import * as React from 'react';
import { Wrapper, Title, List } from './navigation-list.styled';

interface NavigationListPropsInterface {
    title: string;
    children: Array<JSX.Element> | JSX.Element | string;
    social?: boolean;
}

export const NavigationList: React.FC<NavigationListPropsInterface> = ({ title, children, social = false }) => {
    return (
        <Wrapper>
            <Title>{title}</Title>
            <List social={social}>{children}</List>
        </Wrapper>
    );
};
