import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    () => css`
        display: flex;
        flex-direction: row;
        padding: 5rem 0;
    `,
);

export const Navigation = styled.div`
    ${({ theme }) => css`
        display: grid;
        grid-template-columns: 1fr;
        gap: 3rem;
        background-color: #f7fcff;
        flex-wrap: wrap;
        padding: 3rem;
        margin: 2rem 0;
        border-radius: ${theme.radius.large};

        @media ${theme.breakpoints.lg.min} {
            padding: 5rem;
            gap: 0;
            margin: 5rem -5rem;
            border-radius: ${theme.radius.large};
            grid-template-columns: 2fr 1fr 1fr;
        }

        @media ${theme.breakpoints.vl.min} {
            grid-template-columns: 2fr 1fr 1fr 2fr;
        }
    `}
`;

export const Copy = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        width: 100%;
        font-size: 1.4rem;
        line-height: 1.4rem;
        justify-content: center;

        @media ${theme.breakpoints.xs.min} {
            flex-direction: row;
            gap: 2rem;
            justify-content: space-between;
        }
    `}
`;

export const Copyright = styled.div(
    ({ theme }) => css`
        color: ${theme.palette.color.gray.main};
        display: flex;
        align-items: center;
        gap: 0.5rem;

        a {
            color: ${theme.palette.color.gray.main};
            text-decoration: underline;

            &:hover {
                color: ${theme.palette.color.primary.main};
            }
        }

        @media (max-width: 768px) {
            width: 100%;
            text-align: center;
        }
    `,
);

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const SocialLinks = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const Cookies = styled.div(
    ({ theme }) => css`
        line-height: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 2.6rem;
        width: 2.6rem;
        border-radius: 100%;
        border: 1px solid ${theme.palette.color.gray.opacity.high};
        transition: all ${theme.base.transition};
        color: ${theme.palette.color.secondary.main};
        cursor: pointer;

        i {
            width: 1.4rem;
            height: 1.4rem;
        }

        &:hover {
            background: ${theme.button.variant.primary.filled.hover.background};

            i {
                color: ${theme.palette.color.white.main};
            }
        }
    `,
);
