import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../providers/root-store-provider';

interface ErrorAlertProps {
    email: string;
}

export const ErrorAlertEmailNotConfirmed: React.FC<ErrorAlertProps> = observer(({ email }) => {
    const { t } = useTranslation();
    const { authStore, alertStore } = useRootStore();

    return (
        <Link
            component="button"
            color="secondary"
            onClick={async () => {
                try {
                    await authStore.sendVerificationEmail(email);
                    alertStore.setErrorMessage('');
                    alertStore.setSuccessMessage(t('layout.alerts.successMessage'));
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                }
            }}
        >
            {t('layout.alerts.sendVerificationEmail')}
        </Link>
    );
});
