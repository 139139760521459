import React from 'react';
import { Alert } from '../../app/alert/alert';

interface SuccessAlertProps {
    message: string;
    onCloseClick: () => void;
}

export const SuccessAlert: React.FC<SuccessAlertProps> = ({ message, onCloseClick }) => {
    return (
        <Alert onCloseClick={onCloseClick} type="success" icon>
            {message}
        </Alert>
    );
};
