import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconFacebook: React.FC = () => (
    <Icon viewBox="0 0 16 16" fill="none">
        <path
            d="M16 8.048a8 8 0 1 0-9.25 7.903v-5.59H4.719V8.048H6.75V6.285c0-2.004 1.195-3.112 3.021-3.112.6.008 1.2.06 1.792.156v1.969h-1.01a1.156 1.156 0 0 0-1.303 1.25v1.5h2.217l-.355 2.313H9.245v5.59A8 8 0 0 0 16 8.048Z"
            fill="currentColor"
        />
    </Icon>
);
