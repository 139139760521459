import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SignUpUserMobxDto } from '../../../models/mobx/dtos/auth/sign-up-user-mobx-dto';
import { FormItem } from '../../app/form/item/item';
import { TextField } from '../../app/form/textfield/textfield';
import { Button } from '../../app/form/button/button';
import { useRootStore } from '../../../providers/root-store-provider';
import { AgreeTerms } from '../dialogs/account/agree/terms/agree-terms';
import { Agree } from '../dialogs/account/agree/agree';
import { Tooltip } from '../../app/tooltip/tooltip';

export interface SignUpFormPropsInterface {
    onSubmit: (values: SignUpUserMobxDto) => void;
    loading?: boolean;
}

export const SignUpForm: React.FC<SignUpFormPropsInterface> = observer((props) => {
    const { t } = useTranslation();
    const { onSubmit, loading } = props;
    const {
        authStore: { authDialogStore },
        alertStore,
    } = useRootStore();

    const initialValues = new SignUpUserMobxDto();
    const validationSchema = yup.object({
        fullName: yup.string().required(t('layout.forms.common.nameValidation')),
        email: yup
            .string()
            .required(t('layout.forms.common.emailValidationRequired'))
            .email(t('layout.forms.common.emailValidationValid'))
            .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, t('layout.forms.common.emailValidationValid')),
        password: yup.string().required(t('layout.forms.common.passwordValidation')),
        passwordConfirm: yup
            .string()
            .required(t('layout.forms.common.passwordConfirmValidation'))
            .oneOf([yup.ref('password'), null], t('layout.forms.common.passwordConfirmValidationEquality')),
    });

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, handleChange, touched, handleBlur, errors, handleSubmit }) => (
                <Form id={'signup-form'}>
                    <FormItem>
                        <Agree marginBottom={'medium'}>
                            <AgreeTerms handleChange={handleChange} />
                        </Agree>
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            label={t('layout.forms.common.nameField')}
                            name="fullName"
                            value={values.fullName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.fullName && errors.fullName ? errors.fullName : ''}
                            error={Boolean(touched.fullName && errors.fullName)}
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            label={t('layout.forms.common.emailField')}
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.email && errors.email ? errors.email : ''}
                            error={Boolean(touched.email && errors.email)}
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            name="password"
                            label={t('layout.forms.common.passwordField')}
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.password && errors.password ? errors.password : ''}
                            error={Boolean(touched.password && errors.password)}
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            fullWidth
                            name="passwordConfirm"
                            label={t('layout.forms.signUp.confirmPasswordField')}
                            type="password"
                            value={values.passwordConfirm}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.passwordConfirm && errors.passwordConfirm ? errors.passwordConfirm : ''}
                            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                        />
                    </FormItem>
                    <FormItem>
                        {!authDialogStore.isAgreeCheckboxChecked ? (
                            <Tooltip content={t('layout.forms.common.agreeTermsValidation')} position={'top'} block>
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (authDialogStore.isAgreeCheckboxChecked) {
                                            handleSubmit();
                                        } else {
                                            alertStore.setErrorMessage(t('layout.forms.common.agreeTermsValidation'));
                                        }
                                    }}
                                    block
                                    variant="primary"
                                    loading={loading}
                                    disabled
                                >
                                    {t('layout.forms.signUp.register')}
                                </Button>
                            </Tooltip>
                        ) : (
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (authDialogStore.isAgreeCheckboxChecked) {
                                        handleSubmit();
                                    } else {
                                        alertStore.setErrorMessage(t('layout.forms.common.agreeTermsValidation'));
                                    }
                                }}
                                block
                                variant="primary"
                                loading={loading}
                            >
                                {t('layout.forms.signUp.register')}
                            </Button>
                        )}
                    </FormItem>
                </Form>
            )}
        </Formik>
    );
});
