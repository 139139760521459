import React, { useMemo } from 'react';
import { Wrapper } from './social.styled';
import { IconFacebook } from '../../app/icons/facebook';
import { IconInstagram } from '../../app/icons/instagram';
import { IconDiscord } from '../../app/icons/discord';

interface SocialPropsInterface {
    type: 'Facebook' | 'Instagram' | 'Discord';
    link: string;
}

export const Social: React.FC<SocialPropsInterface> = ({ type, link }) => {
    const icon = useMemo(() => {
        if (type === 'Facebook') {
            return <IconFacebook />;
        }
        if (type === 'Instagram') {
            return <IconInstagram />;
        }
        if (type === 'Discord') {
            return <IconDiscord />;
        }
        return null;
    }, [type]);

    return (
        <Wrapper>
            <a href={link} title={type} target="_blank" rel="noreferrer">
                {icon}
            </a>
        </Wrapper>
    );
};
