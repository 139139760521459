import Link from 'next/link';
import React from 'react';
import { Wrapper, IconPrefix, IconSuffix } from './styled-link.styled';

interface StyledLinkPropsInterface {
    children: React.ReactNode;
    iconPrefix?: React.ReactNode;
    iconSuffix?: React.ReactNode;
    href?: string;
    onClick?: () => void;
}

export const StyledLink: React.FC<StyledLinkPropsInterface> = ({
    children,
    href = '#',
    iconPrefix,
    iconSuffix,
    onClick,
}) => {
    if (onClick) {
        return <Wrapper onClick={onClick}>{children}</Wrapper>;
    }

    return (
        <Link href={href}>
            <Wrapper>
                {iconPrefix && <IconPrefix>{iconPrefix}</IconPrefix>}
                {children}
                {iconSuffix && <IconSuffix>{iconSuffix}</IconSuffix>}
            </Wrapper>
        </Link>
    );
};
