import { makeAutoObservable } from 'mobx';

export class ResetPasswordDialogMobxDto {
    password = '';

    passwordConfirm = '';

    constructor() {
        makeAutoObservable(this);
    }
}
