import styled, { css } from 'styled-components';

export const Wrapper = styled.a`
    ${({ theme }) => css`
        display: flex;
        align-items: center;
        gap: 1.6rem;
        cursor: pointer;
        color: inherit;
        text-decoration: none;

        svg {
            height: 1.8rem;
            width: auto;

            path {
                transition: fill ${theme.base.transition};

                fill: ${theme.palette.color.secondary.main};
            }
        }

        &:hover {
            svg {
                path {
                    fill: #000;

                    &.underline {
                        fill: #38c659;
                    }
                }
            }
        }
    `}
`;
