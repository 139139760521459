import React, { useEffect } from 'react';
import { Wrapper, Window, Overlay, Close, Footer, Title, Header } from './modal.styled';
import { IconClose } from '../../app/icons/close';
import { Loading } from '../../app/loading/loading';
import { useRootStore } from '../../../providers/root-store-provider';

interface IModalProps {
    children: React.ReactChild;
    show?: boolean;
    title?: string;
    icon?: React.ReactNode;
    footer?: React.ReactNode;
    onClose?: () => void;
    loading?: boolean;
}

export const Modal: React.FC<IModalProps> = ({ show, title, icon, children, onClose, footer, loading = false }) => {
    useEffect(() => {
        if (show) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [show]);

    const { alertStore } = useRootStore();

    const hasAlerts = !!alertStore.successMessage || !!alertStore.errorMessage || !!alertStore.warningMessage;

    return show ? (
        <Wrapper show={show} hasAlerts={hasAlerts}>
            <Window>
                <Loading active={loading}>
                    <Header>
                        {icon}
                        <Title>{title}</Title>
                    </Header>
                    {children}
                    {footer && <Footer>{footer}</Footer>}
                </Loading>
                <Close onClick={onClose}>
                    <IconClose />
                </Close>
            </Window>

            <Overlay onClick={onClose} />
        </Wrapper>
    ) : null;
};
