import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
    isChecked: boolean;
}>`
    ${({ theme, isChecked }) => css`
        padding: 0.8rem 0 0.8rem 1.8rem;
        display: inline-flex;
        border-radius: ${theme.radius.small};

        ${isChecked &&
        css`
            background-color: ${theme.palette.color.positive.opacity.high};
        `}

        ${!isChecked &&
        css`
            background-color: ${theme.palette.color.warning.opacity.medium};
        `}

        label {
            .MuiCheckbox-root {
                margin: -0.9rem;
                margin-right: 0;
            }
        }
    `}
`;
