import React from 'react';
import { Field, Form, Formik, type FieldInputProps } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { LoginUserMobxDto } from '../../../models/mobx/dtos/auth/login-user-mobx-dto';
import { TextField } from '../../app/form/textfield/textfield';
import { Checkbox } from '../../app/form/checkbox/checkbox';
import { FormItem } from '../../app/form/item/item';
import { Button } from '../../app/form/button/button';

export interface LoginFormPropsInterface {
    onSubmit: (values: LoginUserMobxDto) => void;
    loading?: boolean;
}

export const LoginForm: React.FC<LoginFormPropsInterface> = observer((props) => {
    const { t } = useTranslation();
    const { onSubmit, loading } = props;

    const initialValues = new LoginUserMobxDto();
    const validationSchema = yup.object({
        email: yup
            .string()
            .required(t('layout.forms.common.emailValidationRequired'))
            .email(t('layout.forms.common.emailValidationValid')),
        password: yup.string().required(t('layout.forms.common.passwordValidation')),
        rememberMe: yup.boolean(),
    });

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, handleChange, touched, handleBlur, errors, handleSubmit }) => (
                <Form id={'login-form'}>
                    <FormItem>
                        <TextField
                            margin="normal"
                            fullWidth
                            label={t('layout.forms.common.emailField')}
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.email && errors.email ? errors.email : ''}
                            error={Boolean(touched.email && errors.email)}
                        />
                    </FormItem>
                    <FormItem>
                        <TextField
                            margin="normal"
                            fullWidth
                            name="password"
                            label={t('layout.forms.common.passwordField')}
                            type="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.password && errors.password ? errors.password : ''}
                            error={Boolean(touched.password && errors.password)}
                        />
                    </FormItem>
                    <FormItem>
                        <Field name="rememberMe">
                            {(field: FieldInputProps<boolean>) => (
                                <Checkbox
                                    {...field}
                                    value={values.rememberMe.toString()}
                                    name="rememberMe"
                                    checked={values.rememberMe}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    label={t('layout.forms.common.rememberCheckBox')}
                                />
                            )}
                        </Field>
                    </FormItem>
                    <FormItem>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            variant="primary"
                            block
                            loading={loading}
                        >
                            {t('layout.forms.login.login')}
                        </Button>
                    </FormItem>
                </Form>
            )}
        </Formik>
    );
});
