import styled, { css } from 'styled-components';
import { GapSizeType } from '../../../../../theme/type/gap-type';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['marginTop', 'marginBottom'].includes(prop),
})<{
    marginTop: GapSizeType;
    marginBottom: GapSizeType;
}>`
    ${({ theme, marginTop, marginBottom }) => css`
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: ${theme.gap[marginTop]};
        margin-bottom: ${theme.gap[marginBottom]};

        a {
            color: ${theme.palette.color.secondary.main};
            text-decoration: underline;
            transition: all ${theme.base.transition};

            &:hover {
                text-decoration: none;
                color: ${theme.palette.color.secondary.light};
            }
        }
    `}
`;
