import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconGoogle: React.FC = () => (
    <Icon viewBox="0 0 49 50" fill="none">
        <path
            d="M47.981 20.4812C40.2637 20.4774 32.5464 20.4793 24.8292 20.4802C24.8302 23.6811 24.8254 26.882 24.8311 30.0819C29.3004 30.0809 33.7697 30.08 38.2381 30.0819C37.7202 33.1492 35.8973 35.9542 33.3123 37.681C31.6873 38.7736 29.8203 39.4828 27.8955 39.82C25.9583 40.1506 23.9566 40.1929 22.0242 39.8017C20.0591 39.4096 18.1833 38.5909 16.5469 37.4368C13.9303 35.5985 11.9334 32.9089 10.9081 29.8819C9.8597 26.8031 9.852 23.3773 10.9129 20.3014C11.649 18.1335 12.8742 16.1309 14.4837 14.5012C16.469 12.4688 19.0501 11.0159 21.831 10.4201C24.2122 9.91179 26.7231 10.0088 29.0544 10.7141C31.0359 11.3157 32.8626 12.4015 34.3588 13.8304C35.8713 12.3265 37.3761 10.814 38.8858 9.30729C39.6776 8.49629 40.5098 7.72079 41.2766 6.88769C38.9838 4.76589 36.2961 3.06029 33.3594 1.98209C28.0723 0.0362893 22.1097 -0.0050106 16.7871 1.83719C10.7889 3.89069 5.6815 8.37629 2.8497 14.0459C1.8638 15.9995 1.144 18.0847 0.711604 20.2295C-0.376196 25.5732 0.382004 31.2707 2.8468 36.1378C4.4487 39.3146 6.74531 42.1388 9.53111 44.3528C12.1593 46.4486 15.2227 47.9967 18.4707 48.8606C22.5691 49.9599 26.9317 49.9349 31.0571 48.9961C34.7855 48.138 38.3131 46.3564 41.1296 43.7561C44.1066 41.0203 46.2302 37.4158 47.3545 33.5423C48.5807 29.3171 48.7498 24.8064 47.981 20.4812Z"
            fill="currentColor"
        />
    </Icon>
);
