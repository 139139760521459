import styled from 'styled-components';

export const Wrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    z-index: 920;
    display: flex;
    justify-content: center;
`;

export const Inner = styled.div`
    padding: 2rem;
`;
