import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div(
    ({ theme }) => css`
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.2rem;
        color: ${theme.palette.color.gray.main};
        margin-bottom: 2rem;
        text-transform: uppercase;
    `,
);

export const List = styled.div.withConfig({
    shouldForwardProp: (prop) => !['social'].includes(prop),
})<{
    social: boolean;
}>`
    ${({ theme, social }) => css`
        display: flex;
        flex-direction: column;

        ${social &&
        css`
            flex-direction: row;
            gap: 1.6rem;
        `}

        a {
            font-size: 1.4rem;
            line-height: 3rem;
            color: ${theme.palette.color.secondary.main};

            &:hover {
                text-decoration: underline;
            }
        }
    `}
`;
