import React from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './third-party-login.styled';
import { IconGoogle } from '../../../../app/icons/google';
import { Divider } from '../../../../app/divider/divider';
import { Button } from '../../../../app/form/button/button';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { Tooltip } from '../../../../app/tooltip/tooltip';

export const ThirdPartyLogin: React.FC = observer(() => {
    const { t } = useTranslation();
    const router = useRouter();
    const {
        authStore: { authDialogStore },
        alertStore,
    } = useRootStore();

    const onLogin = () => {
        if (authDialogStore.activeModal !== 'signUp' || authDialogStore.isAgreeCheckboxChecked) {
            router.push(`${process.env.NEXT_PUBLIC_API_URL}/auth/google`);
        } else {
            alertStore.setErrorMessage(t('layout.forms.common.agreeTermsValidation'));
        }
    };

    return (
        <>
            <Divider gap="large">{t('dialogs.common.or')}</Divider>

            <Wrapper>
                {authDialogStore.activeModal === 'signUp' && !authDialogStore.isAgreeCheckboxChecked ? (
                    <Tooltip content={t('layout.forms.common.agreeTermsValidation')} position={'top'} block>
                        <Button block onClick={onLogin} variant="google" icon={<IconGoogle />} disabled>
                            {t('dialogs.common.loginThirdParty')}
                        </Button>
                    </Tooltip>
                ) : (
                    <Button block onClick={onLogin} variant="google" icon={<IconGoogle />}>
                        {t('dialogs.common.loginThirdParty')}
                    </Button>
                )}
            </Wrapper>
        </>
    );
});
