import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ForgottenPasswordMobxDto } from '../../../models/mobx/dtos/auth/forgotten-password-mobx-dto';
import { FormItem } from '../../app/form/item/item';
import { TextField } from '../../app/form/textfield/textfield';
import { Button } from '../../app/form/button/button';

export interface ForgottenPasswordFormPropsInterface {
    onSubmit: (values: ForgottenPasswordMobxDto) => void;
}

export const ForgottenPasswordForm: React.FC<ForgottenPasswordFormPropsInterface> = observer((props) => {
    const { t } = useTranslation();
    const { onSubmit } = props;

    const initialValues = new ForgottenPasswordMobxDto();
    const validationSchema = yup.object({
        email: yup
            .string()
            .required(t('layout.forms.common.emailValidationRequired'))
            .email(t('layout.forms.common.emailValidationValid')),
    });

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, handleChange, touched, handleBlur, errors, handleSubmit }) => (
                <Form id={'forgotten-password-form'}>
                    <FormItem>
                        <TextField
                            fullWidth
                            label={t('layout.forms.common.emailField')}
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            helperText={touched.email && errors.email ? errors.email : ''}
                            error={Boolean(touched.email && errors.email)}
                        />
                    </FormItem>
                    <FormItem>
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            block
                            variant="primary"
                        >
                            {t('layout.forms.forgottenPassword.renewPassword')}
                        </Button>
                    </FormItem>
                </Form>
            )}
        </Formik>
    );
});
