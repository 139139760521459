import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ErrorStatusEnum } from '@app/common';
import { BaseDialog } from '../base-dialog';
import { ForgottenPasswordForm } from '../../forms/forgotten-password-form';
import { ThirdPartyLogin } from './third-party-login/third-party-login';
import { ForgottenPasswordMobxDto } from '../../../../models/mobx/dtos/auth/forgotten-password-mobx-dto';
import { useRootStore } from '../../../../providers/root-store-provider';
import { DialogAccountLinks } from './links/links';
import { IconUser } from '../../../app/icons/user';
import { IconButton } from '../../../app/icon/button/icon-button';

export interface ForgottenPasswordDialogInterface {
    status?: string;
    action?: string;
}

export const ForgottenPasswordDialog: React.FC<ForgottenPasswordDialogInterface> = observer(
    (props: ForgottenPasswordDialogInterface) => {
        const { t } = useTranslation();
        const rootStore = useRootStore();
        const { alertStore } = rootStore;
        const { authDialogStore } = rootStore.authStore;
        const { status } = props;

        useEffect(() => {
            if (status === ErrorStatusEnum.SignedUpDifferently) {
                alertStore.setErrorMessageByStatus(status as ErrorStatusEnum);
                authDialogStore.setLoginActive(true);
            }
        }, []);

        const onForgotPassword = async (values: ForgottenPasswordMobxDto) => {
            try {
                await rootStore.authStore.sendResetPasswordEmail(values);
                authDialogStore.closeDialog();
                rootStore.alertStore.setSuccessMessage(t('dialogs.forgottenPassword.successMessage'));
                // eslint-disable-next-line no-empty
            } catch (e) {}
        };

        return (
            <>
                <BaseDialog
                    opened={authDialogStore.activeModal === 'forgottenPassword'}
                    close={() => authDialogStore.closeDialog()}
                    icon={<IconButton icon={<IconUser />} />}
                    title={t('dialogs.forgottenPassword.title')}
                    content={
                        <>
                            <ForgottenPasswordForm onSubmit={onForgotPassword} />

                            <DialogAccountLinks type={authDialogStore.activeModal} />

                            <ThirdPartyLogin />
                        </>
                    }
                />
            </>
        );
    },
);
