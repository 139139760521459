import styled, { css } from 'styled-components';

import { Wrapper as Button } from '../../app/form/button/button.styled';

export const Wrapper = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show', 'hasAlerts'].includes(prop),
})<{
    show?: boolean;
    hasAlerts?: boolean;
}>`
    ${({ theme, show, hasAlerts }) => css`
        bottom: 0;
        left: 0;
        opacity: 0;
        position: fixed;
        right: 0;
        top: 0;
        padding: 1rem;
        transition: all ${theme.base.transition};
        visibility: hidden;
        z-index: 800;
        backdrop-filter: blur(0.5rem);

        ${hasAlerts &&
        css`
            padding-top: 9rem;
        `}

        ${show &&
        css`
            opacity: 1;
            visibility: visible;
        `}

        @media ${theme.breakpoints.md.min} {
            padding: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;

            ${hasAlerts &&
            css`
                padding-top: 9rem;
            `}
        }
    `}
`;

export const Overlay = styled.div.withConfig({
    shouldForwardProp: (prop) => !['show'].includes(prop),
})<{
    show?: boolean;
}>`
    ${({ theme }) => css`
        background-color: ${theme.modal.overlay.background};
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1100;
    `}
`;

export const Window = styled.div`
    ${({ theme }) => css`
        background-color: ${theme.modal.window.background};
        max-width: 62rem;
        padding: 2rem;
        position: relative;
        width: 100%;
        z-index: 1200;
        max-height: 100%;
        overflow: auto;
        border-radius: ${theme.radius.medium};

        @media ${theme.breakpoints.md.min} {
            padding: 3.2rem 6.4rem 6.4rem;
            height: unset;
        }
    `}
`;

export const Header = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: ${theme.gap.medium} 0 0 0;
    `}
`;

export const Title = styled.div`
    ${({ theme }) => css`
        font-size: ${theme.headline.size.h3.fontSize};
        line-height: ${theme.headline.size.h3.lineHeight};
        color: ${theme.palette.color.secondary.main};
        font-weight: 700;
        margin-top: ${theme.gap.small};
        margin-bottom: ${theme.gap.medium};
    `}
`;

export const Close = styled.div`
    ${({ theme }) => css`
        cursor: pointer;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;

        svg {
            color: ${theme.modal.close.base.color};
            height: 3rem;
            transition: color ${theme.base.transition};
            width: 3rem;
        }

        &:hover {
            svg {
                color: ${theme.modal.close.hover.color};
            }
        }
    `}
`;

export const Footer = styled.div`
    ${({ theme }) => css`
        margin-top: ${theme.gap.medium};
        display: flex;
        justify-content: space-between;
        align-items: center;

        ${Button}:only-child {
            margin-left: auto;
        }
    `}
`;
