import React, { ChangeEvent, useCallback } from 'react';
import { FormControlLabel } from '@mui/material';
import { StyledCheckbox, Wrapper } from './checkbox.styled';
import { CheckboxProps } from './checkbox.type';

export const Checkbox: React.FC<CheckboxProps> = ({ onChange, name, checked = false, value, disabled, label }) => {
    const onChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event);
        }
    }, []);

    return (
        <Wrapper>
            <FormControlLabel
                control={
                    <StyledCheckbox
                        name={name}
                        checked={checked}
                        disabled={disabled}
                        value={value}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeHandler(e)}
                    />
                }
                checked={checked}
                onChange={(event) => onChangeHandler(event as ChangeEvent<HTMLInputElement>)}
                value={value}
                label={label}
            />
        </Wrapper>
    );
};
