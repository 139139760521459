import { makeAutoObservable } from 'mobx';
import { SignUpUserDto } from '../../../../defs/api';

export class SignUpUserMobxDto implements SignUpUserDto {
    fullName: string = '';

    email: string = '';

    password: string = '';

    affiliateCode: string = '';

    passwordConfirm: string = '';

    constructor() {
        makeAutoObservable(this);
    }

    static create(data: SignUpUserDto): SignUpUserMobxDto {
        const mobxDto = new SignUpUserMobxDto();
        return Object.assign(mobxDto, data);
    }

    static createFromArray(dtos: SignUpUserDto[]): SignUpUserMobxDto[] {
        return dtos.map((dto) => SignUpUserMobxDto.create(dto));
    }
}
