import React from 'react';
import { observer } from 'mobx-react-lite';
import { Wrapper } from './agree.styled';
import { GapSizeType } from '../../../../../theme/type/gap-type';

type AgreeType = {
    children: React.ReactNode;
    marginTop?: GapSizeType;
    marginBottom?: GapSizeType;
};

export const Agree: React.FC<AgreeType> = observer(
    ({ children, marginTop = 'none', marginBottom = 'none' }: AgreeType) => {
        return (
            <Wrapper marginTop={marginTop} marginBottom={marginBottom}>
                {children}
            </Wrapper>
        );
    },
);
