import { makeAutoObservable } from 'mobx';
import { LoginUserDto } from '../../../../defs/api';

export class LoginUserMobxDto implements LoginUserDto {
    email: string = '';

    password: string = '';

    rememberMe: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    static create(data: LoginUserDto): LoginUserMobxDto {
        const mobxDto = new LoginUserMobxDto();
        return Object.assign(mobxDto, data);
    }

    static createFromArray(dtos: LoginUserDto[]): LoginUserMobxDto[] {
        return dtos.map((dto) => LoginUserMobxDto.create(dto));
    }
}
