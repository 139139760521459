import React from 'react';
import { observer } from 'mobx-react-lite';
import { Alert } from '../../app/alert/alert';

interface ErrorAlertProps {
    message: string;
    afterMessage?: React.ReactNode;
    loading: boolean;
    onCloseClick: () => void;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = observer(({ afterMessage, message, loading, onCloseClick }) => {
    return (
        <Alert type="error" onCloseClick={onCloseClick} icon loading={loading}>
            {message}
            {afterMessage}
        </Alert>
    );
});
