import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRootStore } from '../../../../../providers/root-store-provider';
import { Wrapper } from './links.styled';
import { Text } from '../../../../app/text/text';
import { StyledLink } from '../../../../app/styled-link/styled-link';

interface DialogAccountLinksPropsInterface {
    type?: 'login' | 'forgottenPassword' | 'signUp' | 'emailConfirmation' | 'resetPassword' | null;
}

export const DialogAccountLinks: React.FC<DialogAccountLinksPropsInterface> = ({ type = null }) => {
    const { t } = useTranslation();
    const rootStore = useRootStore();
    const { authDialogStore } = rootStore.authStore;

    return (
        <Wrapper>
            <Text gap="xsmall" size="large" align="left" semiBold>
                {(type === 'login' || type === 'signUp') && (
                    <StyledLink onClick={() => authDialogStore.setForgottenPassActive()}>
                        {t('dialogs.common.forgottenPassword')}
                    </StyledLink>
                )}
                {(type === 'resetPassword' || type === 'forgottenPassword') && (
                    <>
                        {t('dialogs.common.alreadyHaveAccount')}{' '}
                        <StyledLink onClick={() => authDialogStore.setLoginActive()}>
                            {t('dialogs.common.logIn')}
                        </StyledLink>
                    </>
                )}
            </Text>
            <Text gap="xsmall" size="large" align="right" semiBold>
                {(type === 'login' || type === 'resetPassword' || type === 'forgottenPassword') && (
                    <>
                        {t('dialogs.common.dontHaveAccount')}{' '}
                        <StyledLink onClick={() => authDialogStore.setSignupActive()}>
                            {t('dialogs.common.signUp')}
                        </StyledLink>
                    </>
                )}
                {type === 'signUp' && (
                    <>
                        {t('dialogs.common.alreadyHaveAccount')}{' '}
                        <StyledLink onClick={() => authDialogStore.setLoginActive()}>
                            {t('dialogs.common.logIn')}
                        </StyledLink>
                    </>
                )}
            </Text>
        </Wrapper>
    );
};
