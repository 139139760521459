import React from 'react';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { ImportTypeEnum } from '@app/common';
import { Copy, Copyright, Main, SocialLinks, Cookies, Navigation } from '../footer.styled';
import { routes } from '../../../../config/routes';
import useCookies from '../../../../hooks/use-cookies';
import { Logo } from '../../../../components/app/logo/logo';
import { Social } from '../../../../components/web/social/social';
import { IconCookies } from '../../../../components/app/icons/cookies';
import { Atreo } from '../../../../components/app/atreo/atreo';
import { NavigationList } from '../../../../components/web/navigation-list/navigation-list';

export const FooterContent: React.FC = () => {
    const { t } = useTranslation();
    const { handleShowPreferences } = useCookies(false);
    return (
        <>
            <Copy>
                <Logo href={routes.web.index} />
                <SocialLinks>
                    <Social type="Facebook" link="https://www.facebook.com/monery.io" />
                    <Social type="Instagram" link="https://www.instagram.com/monery.io" />
                    <Social type="Discord" link="https://discord.gg/n4MYgUh9KF" />
                </SocialLinks>
            </Copy>
            <Navigation>
                <NavigationList title={t('pages.layout.footer.legal')}>
                    <Link href={routes.web.termsAndConditions}>
                        <a>{t('pages.layout.footer.termsAndConditions')}</a>
                    </Link>
                    <Link href={routes.web.privacyPolicy}>
                        <a>{t('pages.layout.footer.privacyPolicy')}</a>
                    </Link>
                    <Link href={routes.web.marketingCommunicationTerms}>
                        <a>{t('pages.layout.footer.marketingCommunicationTerms')}</a>
                    </Link>
                </NavigationList>
                <NavigationList title="Navigace">
                    <a href={routes.web.pricing}>Ceník</a>
                    <a href={routes.web.contact}>Kontakty</a>
                </NavigationList>
                <NavigationList title="Informace">
                    <a href={routes.web.faq}>Často kladené otázky</a>
                    <a href={`${routes.web.exportGuide}?platform=${ImportTypeEnum.Degiro}`}>Návody na export dat</a>
                </NavigationList>
            </Navigation>

            <Copy>
                <Main>
                    <Copyright>
                        &copy; Monery Finance s.r.o &mdash;{' '}
                        <Cookies onClick={handleShowPreferences}>
                            <IconCookies />
                        </Cookies>
                    </Copyright>
                </Main>

                <Atreo />
            </Copy>
        </>
    );
};
