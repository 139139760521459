import React from 'react';
import { Alert } from '../../app/alert/alert';

interface WarningAlertProps {
    message: string;
    loading: boolean;
}

export const WarningAlert: React.FC<WarningAlertProps> = ({ message, loading }) => {
    return (
        <Alert type="warning" icon loading={loading}>
            {message}
        </Alert>
    );
};
