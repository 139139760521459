import styled, { css } from 'styled-components';

export const Wrapper = styled.a(
    ({ theme }) => css`
        cursor: pointer;
        text-decoration: none;
        color: ${theme.palette.color.primary.main};
        display: inline-flex;
        align-items: center;

        &:hover {
            text-decoration: underline;
        }
    `,
);

export const IconPrefix = styled.div(
    ({ theme }) =>
        css`
            margin-right: ${theme.gap.vsmall};
        `,
);
export const IconSuffix = styled.div(
    ({ theme }) =>
        css`
            margin-left: ${theme.gap.vsmall};
        `,
);
